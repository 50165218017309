import React from 'react'
import { Link, StaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import Img from "gatsby-image"
import Logo from '../images/Logo-Landing.svg'
import GlobalHelmet from '../components/GlobalHelmet'

const LandingPageContainer = styled.div`
    position: relative;
    overflow: hidden;
    padding: 0;
    margin: 0;
    width: 100vw;
    height: 100vh;
`

const LandingPageBackgroundContainer = styled.div`
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  width: 200%;
  height: 100%;
  overflow: hidden;
  z-index: 0;
  .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
  }
  @media (min-width: 768px) and (min-height: 768px){
    width: 110%;
  }
  @media (min-width: 1024px) {
    width: 100%;
  }
`

const ContentArea = styled.div`
    z-index: 9999;
    width: 434px;
    max-width: 100%;
    padding: 1rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    text-align: center;
    box-sizing: border-box;
    @media (min-width: 1024px) {
        left: unset;
        right: 20.364583333vw;
        text-align: left;
        transform: translateY(-50%);
    }
`

const Button = styled(Link)`
    color: white;
    background: #1F393F;
    border-radius: 9.27px;
    padding: 15px;
    width: 151px;
    display: inline-block;
    text-align: center;
    font-size: 18.47px;
    line-height: 18.47px;
    text-transform: uppercase;
    margin: 0 1.2rem 1.2rem 1.2rem;
    &:hover {
        text-decoration: none;
    }
    @media (min-width: 768px) {
        margin-left: -5px;
    }
`

const LocationButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    @media (min-width: 768px) {
        justify-content: flex-start;
        flex-wrap: nowrap;
    }
`

class IndexPage extends React.Component {
    render() {
        return (
            <StaticQuery 
                query={graphql`
                {
                    metadata: site {
                        siteMetadata {
                            title
                        }
                }
                landing_background: allFile(filter: {sourceInstanceName: {eq: "images"}, name: {eq: "Landing-Background"}}) {
                    edges {
                        node {
                          name
                          childImageSharp {
                            fluid(quality: 90, maxWidth: 3840) {
                              ...GatsbyImageSharpFluid_withWebp
                            }
                          }
                        }
                      }
                    }
                }
        `}
        render={data => {
            const background = data.landing_background.edges[0].node.childImageSharp.fluid
            return (
                <>
                    <GlobalHelmet title={data.metadata.siteMetadata.title} />
                    <LandingPageContainer>
                        <LandingPageBackgroundContainer>
                            <Img fluid={background} />
                        </LandingPageBackgroundContainer>
                        <ContentArea>
                            <img src={Logo} alt="Element5 Spa" style={{width: '306px', maxWidth: '100%', marginBottom: '1rem'}}/>
                            <p style={{fontSize: '18px', color: '#484C4D', fontWeight: 'bold', marginBottom: '1rem'}}>Please choose a spa location to proceed:</p>
                            <LocationButtonContainer>
                                <div style={{ paddingRight: '1em' }}>
                                    <Button to="/saintjohn">Saint John</Button>
                                    <p style={{ fontWeight: '700', fontSize: '16px', color: '#484C4D'}}>
                                        1 Market Square, 2<sup>nd</sup> level<br />
                                        Saint John, New Brunswick<br />
                                        E2L 4Z6
                                    </p>
                                </div>
                                <div style={{ paddingRight: '1em' }}>
                                    <Button to="/moncton">Moncton</Button>
                                    <p style={{ fontWeight: '700', fontSize: '16px', color: '#484C4D'}}>
                                        1579 Mountain Road<br />
                                        Moncton, New Brunswick<br />
                                        E1G 1A3<br />
                                    </p>
                                </div>
                            </LocationButtonContainer>
                        </ContentArea>
                    </LandingPageContainer>
                </>
            )
        }} />
    )}
}

//<Image /><Img fluid={props.fluid} />

export default IndexPage
